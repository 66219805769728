export default {
  c1: `
    watch: {
      variable(newValue, oldValue) {
        // do something
      }
    }
  `,
  c2: `
    data: () => ({
      variable: 'value'
    })
  `,
  c3: `
    data: () => ({
      variable: {
        name: 'Alan',
        age: 28,
        children: [
          {
            name: 'Roy',
            age: 5
          }
        ]
      }
    })
  `,
  c4: `
    watch: {
      'variable.name': {
        handler: function(newValue, oldValue) {
          // do something
        }
      }
    }
  `,
  c5: `
    watch: {
      variable: {
        handler: function(newValue, oldValue) {
          // do something
        },
        deep: true
      }
    }
  `,
  c6: `
    computed: {
      bigObject() {
        return document
      }
    },

    watch: {
      bigObject: {
        handler: function(newValue, oldValue) {
          // do something
        },
        deep: true
      }
    }
  `,
}
