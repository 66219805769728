<template>
  <div class="ra-basic ra-basic-text">
    <h2>The watcher is bad, deep watcher - memory hell</h2>
    <p>
      Still in doubt? Read it
    </p>
    <br />
    <br />
    <div>
      Default <b>Vue.js</b> app in our test use <b>1.5 MB</b>
      memory of browser tab
      <br />
      <small>(One component, one root, data and template in root)</small>
      <br />
      Lets take common example, for beginning:
      <RaCode>
        {{ code.c1 }}
      </RaCode>
      And we have <b>variable</b>:
      <RaCode>
        {{ code.c2 }}
      </RaCode>
      Now we use <b>2.0 MB</b> memory
      <br />
      Its <b>0.5 MB</b> just for <b>one</b> <b>Watch</b>!
      <br />
      Not to much you say?
      <br />
      This performance actually for next types:
      <br />
      <b>String</b>,
      <b>Number</b>,
      <b>Boolean</b>,
      <b>Null, Undefined</b> etc
      <br />
      <br />
      Lets check next example:
      <RaCode>
        {{ code.c3 }}
      </RaCode>
      In here if we need watch name of our object
      - we need transform our watcher:
      <RaCode>
        {{ code.c4 }}
      </RaCode>
      With transform like this, we have <b>2.2 MB</b>
      <br />
      So, it's spends <b>0.2 MB</b> more than default <b>Watcher</b>
      <br />
      It's not so much, but we need to know it
      <br />
      <br />
      But more often we need <b>watch</b> for whole <b>object</b>
      <br />
      Below we can check next example:
      <RaCode>
        {{ code.c5 }}
      </RaCode>
      This property, <b>deep</b> - watching whole object as we want,
      and because of this,
      you multiply the memory consumption by the number of lines you track
      <br />
      Now by one <b>Watch</b> with <b>deep: true</b>
      attribute we have <b>2.8 - 3 MB</b> memory
      <br />
      Now remember, we have the simplest object with <b>ONE</b> level of nesting
      <br />
      What happen when we try to watch <b>BIG</b>
      object with lots of levels of nesting?
      <br />
      watch belong:
      <RaCode>
        {{ code.c6 }}
      </RaCode>
      And <b>IF</b> you dont get 'Maximum call stack size exceeded',
      <br />
      now we lose <b>22.6 MB</b> by <b>ONE watcher</b>!
      <br />
      In my expirience I saw application, who use
      <b>watch -> $route / $router / $store / $listeners / window</b>
      and other big objects, and summary, <b>SPA</b> apps with this mistake
      (3-8 watchers in 15-20 components) use more than
      <b>150 MB</b> of browser tab!
      <br />
      <br />
      In conclusion, I want you to remember one thing:
      <br />
      Dont use <b>watch</b> if you can do without it, and <b>never</b>
      use <b>deep: true</b> attribute with <b>BIG</b> objects
      <br />
      <br />
      Be aware about the performance
      <br />
      stay tuned
      <br />
      Bye bye
    </div>
    <br />
    <p>
      {{ authors.length > 1 ? 'Authors:' : 'Author:' }}
      <RaLink
        v-for="(author, key) in authors"
        :key="key"
        :link="author.link"
        follow
        styled
      >
        {{ author.nick }}
      </RaLink>
    </p>
    <div
      v-if="vote && vote.up >= 0 && vote.down >= 0"
      class="ra-vote-block"
    >
      <RaButton
        v-title="`${vote.up} votes`"
        vote
        vote-type="up"
        type="success"
        class="ra-vote-button"
        @click="makeVote(postId, true)"
      />
      <RaButton
        v-title="`${vote.down} votes`"
        vote
        vote-type="down"
        type="danger"
        class="ra-vote-button"
        @click="makeVote(postId, false)"
      />
    </div>
  </div>
</template>

<script>
import VTitle from 'v-title'

import RaCode from '@/components/elements/RaCode.vue'
import RaLink from '@/components/elements/RaLink.vue'
import RaButton from '@/components/elements/RaButton.vue'

import fingerVote from '@/mixins/fingerVote'

import codeData from '@/components/articles/Watcher/code'

export default {
  name: 'Watchers',

  components: {
    RaCode,
    RaLink,
    RaButton
  },

  directives: {
    title: VTitle
  },

  mixins: [fingerVote],

  metaInfo: {
    title: 'Watcher are bad, deep watcher - memory hell',
    titleTemplate: '%s | Roman Almazov',

    meta: [
      {
        name: 'description',
        content: 'Still in doubt? Read'
      },
      {
        name: 'og:title',
        content: 'Watcher are bad, deep watcher - memory hell'
      },
      {
        name: 'twitter:title',
        content: 'Watcher are bad, deep watcher - memory hell'
      },
      {
        name: 'og:description',
        content: 'Still in doubt? Read'
      },
      {
        name: 'twitter:description',
        content: 'Still in doubt? Read'
      },
      { name: 'og:url', content: 'https://ralmaz.pro/blog/ordering' },
      {
        name: 'twitter:url',
        content: 'https://ralmaz.pro/blog/ordering'
      },

      { name: 'og:type', content: 'article' },
      { name: 'article:published_time', content: '2020-05-15' },
      { name: 'article:modified_time', content: '2020-05-16' },
      { name: 'article:author', content: 'RALMAZ' },
      { name: 'article:section', content: 'Vue.js' },
      { name: 'article:tag', content: 'Vue.js' },
      { name: 'article:tag', content: 'JavaScript' }
    ]
  },

  data: () => ({
    postId: 3,
    createdAt: '2020-05-15',
    authors: [
      {
        nick: '@RALMAZ',
        link: 'https://ralmaz.pro'
      }
    ]
  }),

  computed: {
    code() {
      return codeData
    }
  },

  mounted() {
    this.initFingerVote(this.postId)
  }
}
</script>
